import * as React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import Banner from "../../components/BannerComp/Banner";
import Steps from "../../components/Steps/Steps";
import Contact from "../../components/Contact/Contact";
import Brands from "../../components/Brand/Brands";
import Mortgage from "../../components/MortgageForm/MortgageForm";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import "./AwardsAndRecognition.scss";
const bannerData = {
  heading: "Awards and Recognition",
  details:
    "Etiam cursus augue ac maximus maximus. Phasellus lobortis ante at erat fringilla, viverra pulvinar augue dictum quisque molestie lacinia lectus.",
  rating: 4.9,
  maxRating: 5,
  rateCount: 28,
};
const AwardsAndRecognition = () => {
  return (
    <>
      <div className="awards-wrap">
        <Header />
        <Banner bannerData={bannerData} />
        <Steps />
        <Contact />
        <Brands />
        <div className="awards-mortgage awards-wrapper">
          <Container>
            <Row>
              <Col md={12} className="d-xl-flex justify-content-between">
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft">
                  <div className="title-mortgage">
                    <h3>Calculate your mortgage repayments</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nam molestie iaculis arcu, a aliquet eros volutpat sed.
                    </p>
                  </div>
                </AnimationOnScroll>
                <Mortgage />
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default AwardsAndRecognition;
