import React, { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import MObBrand01 from "../../images/awards/mob-brand01.jpg";
import MObBrand02 from "../../images/awards/mob-brand02.jpg";

import TabBrand01 from "../../images/awards/tab-brand01.jpg";
import TabBrand02 from "../../images/awards/tab-brand02.jpg";
import TabBrand03 from "../../images/awards/tab-brand03.jpg";
import TabBrand04 from "../../images/awards/tab-brand04.jpg";

import Brand01 from "../../images/awards/brand01.jpg";
import Brand02 from "../../images/awards/brand02.jpg";
import Brand03 from "../../images/awards/brand03.jpg";
import Brand04 from "../../images/awards/brand04.jpg";
import Brand05 from "../../images/awards/brand05.jpg";
import Brand06 from "../../images/awards/brand06.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import { AnimationOnScroll } from "react-animation-on-scroll";

import "./Brands.scss";

const Brands = (props) => {
  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 375,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          dots: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          dots: false,
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          dots: false,
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <section className="brands-section">
        <Container>
          <Row>
            <Col>
              <div>
                <div className="heading">
                  <h2>Lenders we work with</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit nam
                    molestie iaculis arca aliquet
                  </p>
                </div>
                <Slider {...settings}>
                  <div className="brands">
                    <Link href="#" className="brands-img zoom">
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={Brand01}
                          alt="img"
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={TabBrand01}
                          alt="img"
                        />
                        <img src={MObBrand01} alt="img" />
                      </picture>
                    </Link>
                  </div>
                  <div className="brands ">
                    <Link href="#" className="brands-img zoom">
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={Brand02}
                          alt="img"
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={TabBrand02}
                          alt="img"
                        />
                        <img src={MObBrand02} alt="img" />
                      </picture>
                    </Link>
                  </div>
                  <div className="brands">
                    <Link href="#" className="brands-img zoom">
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={Brand03}
                          alt="img"
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={TabBrand03}
                          alt="img"
                        />
                        <img src={MObBrand01} alt="img" />
                      </picture>
                    </Link>
                  </div>
                  <div className="brands">
                    <Link href="#" className="brands-img zoom">
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={Brand04}
                          alt="img"
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={TabBrand04}
                          alt="img"
                        />

                        <img src={MObBrand02} alt="img" />
                      </picture>
                    </Link>
                  </div>
                  <div className="brands">
                    <Link href="#" className="brands-img zoom">
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={Brand05}
                          alt="img"
                        />
                        <img src={MObBrand01} alt="img" />
                      </picture>
                    </Link>
                  </div>
                  <div className="brands">
                    <Link href="#" className="brands-img zoom">
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={Brand06}
                          alt="img"
                        />
                        <img src={MObBrand02} alt="img" />
                      </picture>
                    </Link>
                  </div>
                  <div className="brands">
                    <Link href="#" className="brands-img zoom">
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={Brand01}
                          alt="img"
                        />
                        <img src={MObBrand01} alt="img" />
                      </picture>
                    </Link>
                  </div>
                  <div className="brands">
                    <Link href="#" className="brands-img zoom">
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={Brand02}
                          alt="img"
                        />
                        <img src={MObBrand01} alt="img" />
                      </picture>
                    </Link>
                  </div>
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Brands;
